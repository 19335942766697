<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Horarios" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.detalles_tipos_horarios`]="{ item }">
          <v-edit-dialog v-if="item.detalles_tipos_horarios.length > 0">
            {{ weekText(item.detalles_tipos_horarios) }}
            <v-tooltip top
              ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading">mdi-calendar-clock-outline</v-icon></template
              ><span>Ver horarios de {{ item.nombre }} </span></v-tooltip
            >
            <template v-slot:input>
              <div class="text-center">
                <h3 class="title text-center" v-text="item.nombre" />
              </div>
              <Schedule-Table :scheduleData="item.detalles_tipos_horarios" />
            </template>
          </v-edit-dialog>
          <span v-else> Horarios no disponibles </span>
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idtipo_horario)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>

    <Dialog-Form :estate="dialogs.new" title="Nuevo horario" maxWidth="800px">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12" class="padding-bottom">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-calendar-blank-outline" :rules="rules.max50" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="padding-top">
            <v-text-field v-model="forms.new.comentario" label="Comentario" prepend-icon="mdi-calendar-text-outline" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headersSchedule" :expanded.sync="expandedReference" :single-expand="true" item-key="dia" show-expand :items="forms.new.detalle" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <Basic-Btn text="Agregar día" color="primary" icon="mdi-plus" @click="addItem('new')" :loading="loading" :disabled="forms.new.detalle.length == 7" />
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="lessItem(item, 'new')">mdi-delete</v-icon></template
                  ><span>Eliminar</span></v-tooltip
                >
              </template>
              <template v-slot:[`item.dia`]="{ item }">
                {{ weekDays[item.dia - 1] }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="container-padding">
                    <v-row>
                      <v-col cols="12">
                        <v-select @change="avaibleDay(item, 'new')" v-model="item.dia" :items="weekendList" item-text="name" item-value="key" label="Día" prepend-icon="mdi-calendar-range-outline" :rules="rules.max18" :disabled="loading" outlined dense required> </v-select>
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_inicio" label="Hora de inicio" />
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_fin" label="Hora de fin" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_comida_inicio" label="Hora inicio de comida" />
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_comida_fin" label="Hora fin de comida" />
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                </td>
              </template>
              <template v-slot:no-data>
                <Basic-Btn text="Agregar día" color="primary" icon="mdi-plus" @click="addItem('new')" :loading="loading" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar horario" maxWidth="800px">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-calendar-blank-outline" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="padding-bottom">
            <v-text-field v-model="forms.update.comentario" label="Comentario" prepend-icon="mdi-calendar-text-outline" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="padding-top">
            <v-data-table :headers="headersSchedule" :expanded.sync="expandedReference" :single-expand="true" item-key="iddetalle_tipo_horario" show-expand :items="forms.update.detalle" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <Basic-Btn text="Agregar día" color="primary" icon="mdi-plus" @click="addItem('update')" :loading="loading" :disabled="forms.update.detalle.length == 7" />
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="lessItem(item, 'update')">mdi-delete</v-icon></template
                  ><span>Eliminar</span></v-tooltip
                >
              </template>
              <template v-slot:[`item.dia`]="{ item }">
                {{ weekDays[item.dia - 1] }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="container-padding">
                    <v-row>
                      <v-col cols="12">
                        <v-select @change="avaibleDay(item, 'update')" v-model="item.dia" :items="weekendList" item-text="name" item-value="key" label="Día" prepend-icon="mdi-calendar-range-outline" :rules="rules.max18" :disabled="loading" outlined dense required> </v-select>
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_inicio" label="Hora de inicio" />
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_fin" label="Hora de fin" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_comida_inicio" label="Hora inicio de comida" />
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="item.hora_comida_fin" label="Hora fin de comida" />
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                </td>
              </template>

              <template v-slot:no-data>
                <Basic-Btn text="Agregar día" color="primary" icon="mdi-plus" @click="addItem('update')" :loading="loading" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>

    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el horario?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, HourPicker, ScheduleTable } from "@/components";
import moment from "moment";

export default {
  name: "group",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    HourPicker,
    ScheduleTable,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idtipo_horario" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Comentarios", align: "left", sortable: true, value: "comentario" },
      { text: "Horarios", align: "left", sortable: true, value: "detalles_tipos_horarios" },
      { text: "Fecha de creación", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    headersSchedule: [
      { text: "Día", align: "left", sortable: true, value: "dia" },
      { text: "Hora de entrada", align: "left", sortable: true, value: "hora_inicio" },
      { text: "Hora de salida", align: "left", sortable: true, value: "hora_fin" },
      { text: "Inicio hora de comida", align: "left", sortable: false, value: "hora_comida_inicio" },
      { text: "Fin hora de comida", align: "left", sortable: false, value: "hora_comida_fin" },
      { text: "Acciones", align: "center", sortable: false, value: "actions" },
      { text: "Expandir", align: "center", value: "data-table-expand" },
    ],
    weekDays: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
    weekendList: [
      {
        name: "Lunes",
        key: 1,
      },
      {
        name: "Martes",
        key: 2,
      },
      {
        name: "Miercoles",
        key: 3,
      },
      {
        name: "Jueves",
        key: 4,
      },
      {
        name: "Viernes",
        key: 5,
      },
      {
        name: "Sabado",
        key: 6,
      },
      {
        name: "Domingo",
        key: 7,
      },
    ],
    items: [],
    users: [],
    selected: [],
    expandedReference: [],

    deleted: null,
    forms: {
      new: {
        nombre: "",
        comentario: "",
        detalle: [],
      },
      update: {
        idtipo_horario: null,
        nombre: "",
        comentario: "",
        detalle: [],
      },
    },
    rules: {
      max50: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  computed: {
    exportItems() {
      let array = [];
      for (let data of this.items) {
        array.push({
          id: data.idtipo_horario ? data.idtipo_horario : "",
          nombre: data.nombre ? data.nombre : "",
          comentario: data.nombre ? data.comentario : "",
          horario: data.nombre ? weekText(data.nombre) : "",
          fecha_creacion: data.fecha_creacion ? this.dateFormat(data.fecha_creacion) : "",
        });
      }
      return array;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.scheduleType)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        this.forms.new = {
          nombre: "",
          comentario: "",
          detalle: [],
        };
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.scheduleType, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Horario creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.forms.update.detalle = this.forms.update.detalles_tipos_horarios;
      delete this.forms.update.fecha_actualizacion;
      delete this.forms.update.fecha_creacion;
      delete this.forms.update.usuario_actualizacion;
      delete this.forms.update.usuario_creacion;
      delete this.forms.update.detalles_tipos_horarios;
      let cleanList = ["hora_inicio", "hora_fin", "hora_comida_inicio", "hora_comida_fin"];
      for (let day of this.forms.update.detalle) {
        for (let keys of cleanList) {
          if (day[keys]) {
            this.forms.update.detalle[this.forms.update.detalle.indexOf(day)][keys] = day[keys].slice(0, 5);
          }
        }
      }
      this.forms.update.detalle = this.orderDays(this.forms.update.detalle);
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        data.detalle = this.orderDays(data.detalle);
        this.axios
          .put(services.routes.scheduleType, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Horario actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.scheduleType, { data: { data: { idtipo_horario: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Horario eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    weekText(days) {
      let week = 0,
        weekend = 0,
        allWeek = 0,
        totalDays = [],
        text = "",
        value = "";
      for (let day of days) {
        week += this.limitDays(day, 1, 5);
        weekend += this.limitDays(day, 6, 7);
        allWeek += this.limitDays(day, 1, 7);
        totalDays.push(day.dia);
      }
      if (week == 5) {
        text = "Entre semana";
        totalDays = totalDays.filter((item) => item > 5);
      }
      if (weekend == 2) {
        text = "Fin de semana";
        totalDays = totalDays.filter((item) => item <= 5);
      }

      if (allWeek == 7) {
        text = "Toda la semana";
      }
      value = text + (totalDays.length > 0 ? (text !== "" ? ", " : "") + this.nameDays(totalDays) : "");

      return value;
    },
    limitDays(day, i, j) {
      let value = 0;
      if (day.dia >= i && day.dia <= j) {
        value += 1;
      }
      return value;
    },
    nameDays(days) {
      let result = "";
      for (let day of days) {
        result += this.weekDays[day - 1] + (days.indexOf(day) + 1 == days.length ? "" : ", ");
      }
      return result;
    },
    addItem(type) {
      if (this.forms[type].detalle.length < 7) {
        this.forms[type].detalle.push({
          dia: this.forms[type].detalle.length + 1,
          hora_inicio: "08:00",
          hora_fin: "17:00",
          hora_comida_inicio: "12:00",
          hora_comida_fin: "13:00",
        });
      }
    },
    avaibleDay(item, type) {
      let count = 0;
      for (let day of this.forms[type].detalle) {
        if (item.dia == day.dia) {
          count += 1;
        }
      }
      if (count > 1) {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Día " + this.weekDays[item.dia - 1] + " no disponible";
        item.dia = 0;
      }
    },
    lessItem(item, type) {
      this.forms[type].detalle.splice(this.forms[type].detalle.indexOf(item), 1);
    },
    orderDays(object) {
      let order = [];
      if (object) {
        order = object.sort(function (a, b) {
          if (a.dia < b.dia) {
            return -1;
          }
        });
      }
      return order;
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.container-padding {
  padding-top: 1vw !important;
}
.padding-bottom {
  padding-bottom: 0% !important;
}
.padding-top {
  padding-top: 0% !important;
}
</style>
